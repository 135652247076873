<template>
  <div class="message-box-box">
    <div class="message-box scorr-roll">
      <div>
        <BasicMessage
          :basicInfo="goodsMessage.basicInfo"
          :picture="goodsMessage.picture"
          :manufacturerInfo="goodsMessage.manufacturerInfo"
          :label="goodsMessage.label"
          :manuData="goodsZZData"
          :imgDate="goodsZZData.picture_url"
          :labData="goodsZZData"
        ></BasicMessage>
      </div>
      <div>
        <SpecificationBook
          :instruction="goodsMessage.instruction"
          :instDate="goodsZZData.content"
        ></SpecificationBook>
        <!--  -->
      </div>
    </div>
    <div class="btn-box1">
      <div class="pass" @click="ReadEdit">提交并进入下一步</div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Goods");
import BasicMessage from "./comp/basicMessage.vue";
import SpecificationBook from "./comp/specificationBook.vue";
export default {
  data() {
    return {
      goodsMessage: require("@/assets/json/goodBank.json"), //导入配置json
      goodsZZData: {
        content: {},
      },
      obj: {},
    };
  },
  components: {
    BasicMessage,
    SpecificationBook,
  },
  created() {
    this.GetMessage();
  },
  methods: {
    ...mapActions(["getplatformGoodsdetail", "postplatformGoodsedit"]),
    // 获取详细信息
    async GetMessage() {
      let data = await this.getplatformGoodsdetail({
        id: this.$route.query.id,
      });

      if (data.data.content == null) {
        data.data.content = {};
      }
      //处理商品分类
      data.data.Newgoods_category_id = [];
      if (data.data.goods_category_id.length > 0) {
        data.data.goods_category_id.forEach((item) => {
          data.data.Newgoods_category_id.push({ id: item });
        });
      } else {
        data.data.Newgoods_category_id.push({ id: "" });
      }
      // 处理经营类别
      data.data.Newbusiness_scope_id = [];
      if (data.data.business_scope_id.length > 0) {
        data.data.business_scope_id.forEach((item) => {
          data.data.Newbusiness_scope_id.push({ id: item });
        });
      } else {
        data.data.Newbusiness_scope_id.push({ id: "" });
      }
      this.goodsZZData = data.data;
    },
    // 编辑商品信息
    async ReadEdit() {
      this.goodsZZData.business_scope_id = [];
      this.goodsZZData.goods_category_id = [];
      this.goodsZZData.Newbusiness_scope_id.forEach((item) => {
        this.goodsZZData.business_scope_id.push(item.id);
      });
      this.goodsZZData.Newgoods_category_id.forEach((item) => {
        this.goodsZZData.goods_category_id.push(item.id);
      });
      if (
        this.goodsZZData.business_scope_id.length == 0 ||
        this.goodsZZData.goods_category_id == 0
      ) {
        this.$message({
          message: "经营类别或商品分类未选择",
          type: "warning",
        });
        return;
      }
      if(this.goodsZZData.category=='普药'){
        this.goodsZZData.category=0
      }else if(this.goodsZZData.category=='中药'){
        this.goodsZZData.category=1
      }else if(this.goodsZZData.category=='非药品'){
        this.goodsZZData.category=2
      }
      this.goodsZZData.goods_category_id=this.goodsZZData.all_goods_category
      let data = await this.postplatformGoodsedit(this.goodsZZData);
      if (data.code == 200) {
        this.$router.push({
          path: "/addGood",
          query: { id: this.$route.query.ListID },
        });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
  },
};
</script>
<style scoped></style>
<style lang="scss" scoped>
.message-box-box {
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-top: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  background: #ffffff;
}
.message-box {
  width: 100%;
  max-height: 70vh;
}

.btn-box1 {
  cursor: pointer;
  margin-top: 1%;
  width: 100%;
  height: 40px;
  display: flex;
  align-content: center;
  justify-content: center;
  .pass {
    margin-right: 1%;
    width: none !important ;
    padding: 0px 20px;
    height: 100%;
    background: #00bfbf;
    text-align: center;
    line-height: 40px;
    color: #ffffff;
    font-size: 13px;
    font-weight: 700;
    border-radius: 4px;
  }
  .refuse {
    width: 100px;
    height: 100%;
    background: #d7d7d7;
    text-align: center;
    line-height: 40px;
    color: black;
    font-size: 13px;
    font-weight: 700;
    border-radius: 4px;
  }
}
</style>
