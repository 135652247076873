<template>
  <div>
    <div class="message-box">
      <div class="title">基本信息</div>
      <div class="message-box-box">
        <div class="message-box-left">
          <el-descriptions :column="1">
            <el-descriptions-item
              :label="item.name"
              v-for="(item, index) in basicInfo"
              :key="index + ''"
            >
              <template v-if="item.select || item.radio || item.Cascader">
                <!--  -->
                <template v-if="item.select">
                  <div
                    class="select-span"
                    v-for="(val, i) in manuData[item.key]"
                    :key="i"
                  >
                  
                    <el-select
                      v-model="val.id"
                      :placeholder="`请输入${item.name}`"
                    >
                      <el-option
                        v-for="elem in BasissCopeData[item.key]"
                        :key="elem.id"
                        :label="elem[item.value]"
                        :value="elem.id + ''"
                      >
                      </el-option>
                    </el-select>
                    <span
                      @click="removeType(item.key, i)"
                      class="exceed_color font-size-12"
                      v-if="i != 0"
                      >删除</span
                    >
                    <span
                      class="exceed_color font-size-12"
                      v-if="i == manuData[item.key].length - 1"
                      @click="AddType(item.key)"
                      >添加</span
                    >
                  </div>
                </template>
                <!-- 商品分类次级联动 -->
                <template v-if="item.Cascader">
                    <div
                  v-for="(val, i) in manuData.all_goods_category"
                    :key="i"
                >
                  <el-cascader  
                    class="category"
                    v-model="manuData.all_goods_category[i]"
                    :options="BasissCopeData.Newgoods_categoryList"
                    :props="propsData"
                    :placeholder="`请输入${item.name}`"
                    clearable
                    disabled
                  ></el-cascader>
                  <!-- <span
                        @click="removeClassify (item.selKey, i)"
                        v-if="i != 0"
                        class="exceed_color font-size-12" 
                        >删除</span
                      >
                      <span
                        class="exceed_color font-size-12"
                        @click="AddClassify (item.selKey)"
                        v-if="i == manuData.all_goods_category.length - 1"
                        >添加</span
                      > -->
                </div>
                </template>
                <!--  -->
                <template v-if="item.radio">
                  <el-radio
                    v-for="(v,i) in item.padioCheck"
                    :key="i"
                    v-model="manuData[item.key]"
                    :label="v.val"
                  >
                  {{v.typeName}}</el-radio>
                </template>
              </template>
              <!--  -->
              <template v-else-if="item.mustNumber">
                <el-input
                  v-model="manuData[item.key]"
                  :placeholder="`请输入${item.name}`"
                  type="number"
                  clearable
                ></el-input>
              </template>
              <template v-else>
                <el-input
                  v-model="manuData[item.key]"
                  :placeholder="`请输入${item.name}`"
                  clearable
                ></el-input>
              </template>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="message-box-right">
          <!-- 商品图片 -->
          <div
            class="goods-img-box"
            v-for="(item, index) in picture"
            :key="index + ''"
          >
            <div class="title">{{ item.name }}</div>
            <div class="goods-img">
              <el-image
                v-for="(item, index) in imgDate"
                :key="index"
                style="width: 70px; height: 70px"
                :src="item"
                :preview-src-list="[item]"
              >
              </el-image>
            </div>
          </div>
          <!-- 厂家信息 -->
          <div class="vender-message-box">
            <div class="title">厂家信息</div>
            <div class="vender-message">
              <el-descriptions :column="1">
                <el-descriptions-item
                  :label="item.name"
                  v-for="(item, index) in manufacturerInfo"
                  :key="index"
                >
                  <el-input
                    v-model="manuData[item.key]"
                    :placeholder="`请输入${item.name}`"
                  ></el-input>
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
          <!-- 标签信息 -->
          <div class="label-message-box">
            <div class="title">标签信息</div>
            <div class="label-message">
              <el-descriptions :column="1">
                <el-descriptions-item
                  :label="item.name"
                  v-for="(item, index) in label"
                  :key="index"
                >
                  <template v-if="item.radio">
                    <!-- {{labData[item.key]}} -->
                    <el-radio
                      v-for="(v,i) in item.padioCheck"
                      :key="i"
                      v-model="labData[item.key]"
                      :label="v.val"
                      >{{ v.typeName }}</el-radio
                    >
                  </template>
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  props: {
    basicInfo: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品基本信息
    picture: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品图片
    manufacturerInfo: {
      type: Array,
      default: () => {
        return [];
      },
    }, //厂家信息
    label: {
      type: Array,
      default: () => {
        return [];
      },
    }, //标签信息,
    basicinfoData: {
      type: Object,
      default: () => {
        return {};
      },
    }, //数据商品基本
    imgDate: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品图片
    manuData: {
      type: Object,
      default: () => {
        return {};
      },
    }, //厂家信息
    labData: {
      type: Object,
      default: () => {
        return {};
      },
    }, //标签信息
  },
  data() {
    return {
      options: [],
      propsData: {
        value: "id",
        label: "category_name",
        children: "son",
        //级联选择器配置项
      },
    };
  },
  computed: {
    ...commonIndex.mapState(["BasissCopeData", "goodSIFY"]),
  },
  methods: {
    //删除
    removeType(key, idnex) {
      this.manuData[key].splice(idnex, 1);
    },
    // 添加
    AddType(key) {
      this.manuData[key].push({ id: "" });
    },
    //删除分类
    removeClassify(key, index) {
      // this.manuData[key].splice(idnex, 1);
      this.manuData.all_goods_category.splice(index, 1);;
    },
    // 添加分类
    AddClassify(key) {
      if(this.manuData.all_goods_category.length<10){
        this.manuData.all_goods_category.push([]);
      }
      
    },
  },
};
</script>

<style lang="scss" scoped>
.message-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .title {
    width: 120px;
    margin-top: 10px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00bfbf;
    color: #fff;
    border-radius: 4px;
  }
  .message-box-box {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .message-box-left {
    margin-right: 10px;
    padding-left: 20px;
    padding-top: 10px;
    width: 48%;
    border: 1px solid #797979;
    border-radius: 4px;
  }
  .category{
    margin-bottom: 2px;
  }
  .select-span {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
  }
  .message-box-right {
    width: 48%;
    .goods-img-box {
      width: 100%;
      .el-image {
        margin-right: 5px;
      }
      .goods-img {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        border: 1px solid #797979;
        padding-left: 10px;
        margin-top: 10px;
      }
    }
    .vender-message-box {
      width: 100%;
      .vender-message {
        padding-top: 10px;
        border: 1px solid #797979;
        padding-left: 10px;
        border-radius: 4px;
        margin-top: 10px;
        width: 100%;
      }
    }
    .label-message {
      padding: 10px;
      width: 100%;
      border: 1px solid #797979;
      padding-left: 10px;
      margin-top: 10px;
      border-radius: 4px;
    }
  }
}
::v-deep .el-input__inner{
  line-height: 38px;
}
</style>
